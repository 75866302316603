/**
 * Created by stephanpudras on 22.10.18.
 */

import React from 'react';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import {withNamespaces, withTranslation} from "react-i18next";



const mapStateToProps = (state) => {
    return {
        sidebarData: state.app.sidebar
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

class Breadcrumb extends React.Component {
    constructor(props) {
        super(props);
        this.getBreadCrumbs = this.getBreadCrumbs.bind(this);
    }

    sidebarDeconstructor = (sidebar) => {
        let arr = [];
        sidebar.map(item => {
            arr.push({topic: this.props.t(item.topic), link: item.link, icon:null});
            if(item.hasOwnProperty("subitems")){
                item.subitems.map(subitem => {
                    arr.push({topic: this.props.t(subitem.topic), link: subitem.link, icon:null})
                });
            }
        });
        arr.push({topic: "Kontakt", link: "/contact", icon:null});
        arr.push({topic: "Impressum", link: "/imprint", icon:null});
        arr.push({topic: "Datenschutz", link: "/legal", icon:null});
        arr.push({topic: "Über", link: "/about", icon:null});
        return arr;
    };

    getBreadCrumbs(){
        let pathTokens = this.props.location.pathname.split("/");
        pathTokens.shift();
        let tokens = [];
        tokens.push({topic: "Home", link: "/home", icon: "icon-home"});
        pathTokens.map((token,index) => {
            if(token.length > 0){
                if(token !== "/" && token !== "/home" && token !== "home") {
                    let oItem = this.sidebarDeconstructor(this.props.sidebarData).find(item => item.link === "/" + token);
                    if(typeof oItem !== "undefined"){
                        tokens.push(oItem);
                    }
                }
            }
        });

        if(pathTokens.length < 2 && this.props.location.pathname !== "/" ||this.props.location.pathname !== "/home"){
            let oItem = this.sidebarDeconstructor(this.props.sidebarData).find(item => item.link === this.props.location.pathname);
            if(typeof oItem !== "undefined"){
                tokens.push(oItem);
            }
        }

        return <nav>
                    <ul className="breadcrumb">
                        {
                            tokens.length > 0 && typeof tokens[0] !== "undefined" ? tokens.map((token, index)=> {
                                if(index+1 === tokens.length)
                                    return <li key={"bc_" + index} className="breadcrumb-item active" aria-current="page">{token.icon !== null ? <span className={token.icon}> </span> : token.topic }</li>;
                                else
                                    return <li key={"bc_" + index} className="breadcrumb-item" aria-current="page"><Link to={token.link}>{token.icon != null ? <span className={token.icon}> </span> : token.topic }</Link></li>;
                            })
                                :
                                null
                        }
                    </ul>
                </nav>
    }

    render() {
        return (
            <div>
                {this.props.location.pathname !== "/" ? this.getBreadCrumbs():null}
            </div>
        )
    }
}
const wBreadcrumb = withTranslation(["common"])(Breadcrumb);
export default connect(mapStateToProps, mapDispatchToProps)(wBreadcrumb)